/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Layout from 'layout';
import StartUsing from 'components/ui/startUsing';
import Scrollspy from 'react-scrollspy';
import { format } from 'date-fns';
import * as Styled from './styled';

export default (Content) => ({
  title,
  description,
  img,
  author,
  date,
  url,
  timeToRead,
  customSection,
  ...props
}) => {
  const { authorName, avatar } = author;

  const [tocHeadings, setTocHeadings] = useState(null);
  const [tocContainers, setTocContainers] = useState(null);
  const [tocIDs, setTocIDs] = useState([]);

  useEffect(() => {
    const containers = document.querySelectorAll('.post-container .section-container');
    const headings = document.querySelectorAll('.post-container h2');
    const arrHeadings = Array.from(headings);
    const arrContainers = Array.from(containers);
    if (!tocHeadings) {
      setTocHeadings(arrHeadings);
      setTocContainers(arrContainers);
      const IDs = arrContainers.map((item) => item.id);
      setTocIDs(IDs);
    }
  }, [tocHeadings, tocContainers]);

  const formattedDate = format(new Date(date), 'do MMMM yyyy');

  return (
    <Layout
      metaTitle={`${title} | LiveSession Tutorials`}
      titleOverride
      metaDescription={description}
      canonical={url}
      datePublished={date}
      author={authorName}
      image={img.src}
    >
      <Styled.Section style={{ paddingTop: 0 }}>
        <Styled.ContentContainer>
          <Styled.Header className="container">
            <Styled.Cover alt={title} fluid={img} className="img-fluid" />
            <Styled.HeaderContainer>
              <Styled.Title>{title}</Styled.Title>
              <div className="media blog-post-author">
                <img alt={authorName} src={avatar} className="avatar mr-3" />
                <div className="media-body">
                  <span className="h6">{authorName}</span>
                  <span className="text-small" style={{ opacity: '.75', fontSize: '.75rem' }}>
                    <span>{formattedDate}</span>
                    {timeToRead && <Styled.TimeToRead>{timeToRead} min</Styled.TimeToRead>}
                  </span>
                </div>
              </div>
            </Styled.HeaderContainer>
          </Styled.Header>
        </Styled.ContentContainer>
        <div className="container">
          <div className="row justify-content-center">
            {tocContainers && (
              <div className="col-12 col-md-11 col-lg-4 col-sm-12">
                <Styled.TocHeading>Table of contents</Styled.TocHeading>
                <Scrollspy
                  componentTag={Styled.Toc}
                  offset={-100}
                  items={tocIDs}
                  currentClassName="active"
                >
                  {tocContainers.map(({ id }, i) => (
                    <li key={id}>
                      <a href={`#${id}`}>{tocHeadings[i].textContent}</a>
                    </li>
                  ))}
                </Scrollspy>
              </div>
            )}
            <Styled.ArticleContainer className="col-12 col-md-11 col-lg-8 col-sm-12 post-container">
              <Content {...props} />
            </Styled.ArticleContainer>
          </div>
        </div>
      </Styled.Section>
      <div className="container">{customSection || null}</div>
      <StartUsing />
    </Layout>
  );
};
